import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

const HowIWork = () => (
  <Layout pageInfo={{ pageName: "werkwijze", pageTitle: "Werkwijze", bannerTitle: "website-header-03.png" }}>
    <Seo title="Werkwijze"/>

    <Container>
      <section className="container" id="how-i-work">
        <Row>
          <Col>
            <div class="block">

              <p>
                Elk traject start met een kosteloze telefonische kennismaking. Tijdens dit gesprek kunt u de situatie
                waarvoor u contact met mij zoekt beschrijven, waarna ik kan bepalen of ik iets voor uw kind (of uzelf)
                kan betekenen. Ook bekijken we tijdens dit eerste gesprek of er een klik is, dat is de basis voor een
                succesvol coachingstraject. Wanneer het voorgaande het geval is en u het traject graag wilt starten zal
                een afspraak worden gemaakt voor een intakegesprek. Wanneer sprake is van gezamenlijk gezag heeft het de
                voorkeur dat beide ouders met gezag bij dit intakegesprek aanwezig zijn. In ieder geval zullen beide gezagsdragende ouders met het in te
                zetten traject moeten instemmen. Uw kind zal in principe niet bij het intakegesprek aanwezig zijn.
                Wanneer hier tijdens de telefonische kennismaking aanleiding voor wordt gezien kan worden besloten, mede
                afhankelijk van zijn of haar leeftijd, uw kind bij (een deel van) het gesprek aanwezig te laten zijn.
              </p>

              <p>
                Voor het intakegesprek wordt gemiddeld 1,5 uur uitgetrokken en tijdens dit gesprek zal het
                intakeformulier gezamenlijk worden ingevuld. Aan de hand van dit formulier en het intakegesprek zal ik
                met u proberen te komen tot een helder geformuleerde hulpvraag. Deze hulpvraag zal worden opgenomen in het behandelplan, waarin eveneens een korte beschrijving van de situatie wordt
                gegeven en waarin de doelstellingen van het traject zullen worden bepaald.
              </p>

              <p>
                Wanneer u zich kunt vinden in het behandlplan zal een eerste sessie met uw kind worden gepland. De
                eerste sessie is gericht op elkaar leren kennen en werken aan een vertrouwensband. Het is belangrijk dat
                uw kind zich prettig en op zijn gemak voelt bij mij zodat tot een constructieve samenwerking kan worden
                gekomen. Na deze eerste sessie zal ik het behandelplan evalueren en de uitkomst hiervan wordt
                (telefonisch of tijdens een kort persoonlijk contact) met u besproken. Mogelijk zal op dat moment het
                behandelplan moeten worden aangepast.
              </p>

              <p>
                Na de evaluatie zal het traject verder worden vormgegeven. Het is vooraf moeilijk vast te stellen
                hoeveel sessies nodig zijn. Er zijn verschillende factoren die van invloed zijn op de voortgang van het
                traject zoals de aard van de problemen, de inzet van uw kind en uw betrokkenheid. Na elke sessie wordt
                bekeken of, en zo ja op welk gebied, nog ondersteuning nodig is. Indien wenselijk kan, in overleg met
                zowel u als de leerkracht, ook een observatie op school plaatsvinden. Gedurende het traject zal dan ook
                regelmatig een korte evaluatie met u plaatsvinden en wanneer nodig een uitgebreide evaluatie. Mocht
                blijken dat uw kind andere gespecialiseerde hulp nodig heeft, dan zal ik u daartoe ook adviseren en
                doorverwijzen.
              </p>

              <p>
                Wanneer u, uw kind en ikzelf vinden dat de in het behandelplan vastgestelde doelen zijn behaald zal
                het traject worden afgesloten met een eindevaluatie. Wanneer
                hiertoe aanleiding wordt gezien kan na een periode van 6 tot 12 maanden nog een sessie worden gepland om
                te bezien hoe het in die periode is gegaan. Hiermee kan worden voorkomen dat uw kind terugvalt in zijn
                of haar oude patroon.
              </p>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  </Layout>
)

export default HowIWork
